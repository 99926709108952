import { useEffect, useState } from "react"
import {lookupPhone} from '../../libs/twilio'
import { AuthIcon } from "../Icons"

const Methods = ({data: {sendCode, currentUser, sendPassword, passError}}) => {
  const [method, setMethod] = useState("MAIL")
  const [enterWithCode, setEnterWithCode] = useState(true)
  const [password, setPassword] = useState("")
  const [canWhatsapp, setCanWhatsapp] = useState(true)
  const [isValidPhone, setIsValidPhone] = useState(false)

  const {email, phone, oldUser} = currentUser

  const user_email = email.split('@')[0]
  const user_email_length = user_email.length
  const host_email = email.split('@')[1]

  const {email1, email2, email_asteriks} = {
    email1: user_email_length > 5 ? user_email[0]+user_email[1] : "",
    email2: user_email_length > 5
      ? `${user_email[user_email.length-2]}${user_email[user_email.length-1]}@${host_email}` 
      : `${user_email[user_email.length-1]}@${host_email}`,
    email_asteriks: (user_email_length > 5) ? (user_email_length-4) : (user_email_length <= 5 && user_email_length > 2) ? (user_email_length-1) : (user_email_length == 2) ? 1 : 0
  }

  const phone_length = phone.length
  const last4_numbers = `${phone[phone_length-4]}${phone[phone_length-3]}${phone[phone_length-2]}${phone[phone_length-1]}`
  const phoneAsteriks = phone_length-4

  const PhoneAsteriks = ({length}) => <>{Array.from({ length }, (_, index) => <span key={index}>*</span>)}</>

  const validatePhone = async () => {
    const output = await lookupPhone(phone)
    setCanWhatsapp(output?.mobile)
    setIsValidPhone(output?.mobile)
  }

  useEffect(() => {
    validatePhone()
  }, [])

  return (
    <>
      <div className='flex items-start gap-2'>
        <div className=''><AuthIcon /></div>
        <p className='text-white font-bold text-[20px] tracking-1'>
          {enterWithCode ? 'Te enviaremos un código para identificarte' : 'Ingresá tu contraseña para identificarte'}
        </p>
      </div>
      <div className='flex flex-col gap-2'>
        {enterWithCode 
          ? (
            <>
              <button 
                onClick={() => setMethod("MAIL")}
                className={`rounded px-4 py-3 flex flex-col items-start justify-center ${method === "MAIL" ? 'bg-white text-darkBlue' : ' bg-deactivatedDarkBlue text-white'}`}
              >
                <p className='font-bold'>Envia por email</p>
                <span className={`${method === "MAIL" ? 'text-deactivatedDarkBlue' : ''} text-left text-sm text-start break-all `}>al correo {email1}<PhoneAsteriks length={email_asteriks} />{email2}</span>
              </button>
              <button 
                onClick={() => setMethod("WHATSAPP")}
                disabled={!canWhatsapp}
                className={`rounded px-4 py-3 flex flex-col items-start justify-center ${!canWhatsapp ? "bg-white text-deactivatedDarkBlue" : method ===  "WHATSAPP" ? 'bg-white text-darkBlue' : 'bg-deactivatedDarkBlue text-white'} `}
              >
                {(canWhatsapp && isValidPhone) && <p className='font-bold'>Enviar por WhatsApp</p>}
                {(!canWhatsapp && !isValidPhone) && <span className={`text-deactivatedDarkBlue text-left text-sm`}>
                  Envío por WhastApp no disponible, actualizá tu número al iniciar sesión
                </span>}
                {(canWhatsapp && isValidPhone) && <span className={`${method === "WHATSAPP" ? 'text-deactivatedDarkBlue' : ''} text-left text-sm`}>
                  al número <PhoneAsteriks length={phoneAsteriks} />{last4_numbers}
                </span>}
                {(canWhatsapp && !isValidPhone) && <p className={`text-deactivatedDarkBlue text-left text-sm`}>Validando número celular...</p>}
              </button> 
              <button className='bg-cyan text-white py-4 rounded mt-2' onClick={() => sendCode(method)}>
                Enviar código
              </button>
            </>
          )
          : (
            <>
              <input
                type="text"
                className='h-12 rounded-md outline-none border-none pl-4 mt-2'
                name="password"
                placeholder='Tu contraseña'
                onChange={(e) => setPassword(e.target.value)}
              />
               <p className='text-error text-sm'>{passError}</p>
              <button 
                disabled={!password}
                className={` text-white py-4 rounded ${password ? 'bg-cyan' : 'bg-deactivatedCyan'}`} 
                onClick={() => sendPassword(password)}
              >
                Iniciar sesión
              </button>
            </>
          )
          
        }
        
        {oldUser && <button className="text-cyan underline text-sm pt-4" onClick={() => setEnterWithCode(!enterWithCode)}>
          O entrar con {enterWithCode ? 'contraseña' : 'código'}
        </button>}
      </div>
    </>
  )
}

export default Methods
